import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { API_AUTH_URL } from "../../config";
import AuthContext from "../../context/AuthContext";
import CalendarContext from "../../context/CalendarContext";
import MyLoader from "../UI/loader/MyLoader";
import dayjs from "dayjs";

const DetailedEvent = ({ closeEvent, event, isDetailed }) => {
    const [detailedLoading, setDetailedLoading] = useState(true);
    const [wholeEvent, setWholeEvent] = useState("");

    const {
        authTokens,
        loading,
        setLoading,
        setGeneralError,
        updateToken,
        updateTokenDetailed,
        generalError,
        closeAlert,
        isRefreshingToken,
        setIsRefreshingToken,
    } = useContext(AuthContext);

    const audience = event.classes;
    const docs = event.documents;

    const getDetailedEvent = async () => {
        setDetailedLoading(true);
        try {
            const res = await axios({
                url: `${API_AUTH_URL}/api/v1/events/${event.id}`,
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Accept-Language": "ru",
                    Authorization: `Bearer ${authTokens.access_token}`,
                },
            });
            console.log(res);
            setWholeEvent(res.data);
            setDetailedLoading(false);
        } catch (e) {
            console.log(e);
            setDetailedLoading(false);
            if (e.response.status === 401 && !isRefreshingToken) {
                setDetailedLoading(true);
                setIsRefreshingToken(true); // Set flag to prevent recursion
                await updateTokenDetailed();
                setIsRefreshingToken(false); // Reset flag after refresh
                setDetailedLoading(false);
                await getDetailedEvent();
            } else if (e.response.data.detail) {
                setGeneralError([e.response.status, e.response.data.detail]);
            } else if (e.response.data.code) {
                setGeneralError([e.response.status, e.response.data.code]);
            } else if (e.response.data.non_filed_errors) {
                setGeneralError([
                    e.response.status,
                    e.response.data.non_filed_errors[0],
                ]);
            }
        }
    };

    useEffect(() => {
        if (isDetailed > 0) {
            getDetailedEvent();
        }
    }, [isDetailed]);

    return (
        <>
            {detailedLoading ? (
                <MyLoader />
            ) : (
                <div className="detailed-event">
                    <div className="detailed-closing-block">
                        <i
                            className="material-icons closing-icon"
                            onClick={closeEvent}
                        >
                            close
                        </i>
                    </div>
                    <div className="detailed-event-body">
                        <div className="detailed-name">{wholeEvent.name}</div>
                        {wholeEvent.klasses && (
                            <div className="detailed-audience">
                                {wholeEvent.klasses.map((item) => (
                                    <div
                                        className="detailed-audience-item"
                                        key={item.id}
                                    >
                                        {item.parallel + item.letter}
                                    </div>
                                ))}
                            </div>
                        )}
                        <div className="detailed-date">
                            <a className="detailed-bold-names">
                                Дата проведения:
                            </a>{" "}
                            {dayjs(wholeEvent.date_start).format(
                                "DD.MM.YYYY"
                            ) ===
                            dayjs(wholeEvent.date_end).format("DD.MM.YYYY")
                                ? dayjs(wholeEvent.date_start).format(
                                      "DD.MM.YYYY"
                                  )
                                : dayjs(wholeEvent.date_start).format(
                                      "DD.MM.YYYY"
                                  ) +
                                  " - " +
                                  dayjs(wholeEvent.date_end).format(
                                      "DD.MM.YYYY"
                                  )}
                        </div>{" "}
                        {/* TODO: parse and display date with dayjs */}
                        <div className="detailed-time">
                            <a className="detailed-bold-names">Время:</a>{" "}
                            {dayjs(wholeEvent.date_start).format("HH:mm")} -{" "}
                            {dayjs(wholeEvent.date_end).format("HH:mm")}
                        </div>
                        <div className="detailed-place">
                            <a className="detailed-bold-names">
                                Место проведения:
                            </a>{" "}
                            {wholeEvent.place}
                        </div>
                        {wholeEvent.document &&
                            wholeEvent.document.length > 0 && (
                                <div className="detailed-part-title">
                                    <a className="detailed-bold-names">
                                        Документы:
                                    </a>{" "}
                                </div>
                            )}
                        {wholeEvent.document &&
                            wholeEvent.document.length > 0 && (
                                <div className="detailed-docs">
                                    <div className="detailed-audience">
                                        {wholeEvent.document.map((item) => (
                                            <div
                                                className="detailed-docs-item"
                                                key={item}
                                            >
                                                {item}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        <div className="detailed-vr">
                            <a className="detailed-bold-names">Модуль:</a>{" "}
                            {wholeEvent.module.name}
                        </div>
                        <div className="detailed-external">
                            <a className="detailed-bold-names">
                                Внешнее/внутреннее:
                            </a>{" "}
                            {wholeEvent.is_external ? "Внешнее" : "Внутреннее"}
                        </div>

                        {wholeEvent.is_external && (

                            <div className="detailed-vr">
                                <a className="detailed-bold-names">
                                    Организатор:
                                </a>{" "}
                                {wholeEvent.organizer.name}
                            </div>

                        )}
                        
                        { wholeEvent.linked_document && 
                            <>
                                {wholeEvent.linked_document.length > 0 &&
                                    <div className="detailed-vr">
                                            <a className="detailed-bold-names">Прикрепленная ссылка:</a>{<p></p>}
                                            <a href={wholeEvent.linked_document} target="_blank" rel="noopener noreferrer" className="link-text">{wholeEvent.linked_document}</a>
                                    </div>
                                }
                            </>
                        }
                        { wholeEvent.additional_info && 
                            <>
                            {wholeEvent.additional_info.length > 0 &&
                            <div className="detailed-comment">
                                <a className="detailed-bold-names">Дополнительная информация:</a>{" "}<p></p>
                                {wholeEvent.additional_info}
                            </div>
                            }
                            </>
                        }

                    </div>
                </div>
            )}
        </>
    );
};

export default DetailedEvent;
