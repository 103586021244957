import React, { useState, useEffect, useContext } from "react";
import MyInputText from "../components/UI/input/MyInputText";
import MyButton from "../components/UI/button/MyButton";
import axios from "axios";
import { API_AUTH_URL } from "../config";
import AuthContext from "../context/AuthContext";
import MyToast from "../components/UI/toast/MyToast";
import { ORGANIZATION_NAME } from "../config";

const HoroLogin = () => {
    const [enteredMail, setEnteredMail] = useState("");
    const [entered, setEntered] = useState("");
    const [mailError, setMailError] = useState("");
    const [enteredError, setEnteredError] = useState("");
    const { loginUserHoro, generalError, closeAlert } = useContext(AuthContext);

    useEffect(() => {
        document.title = "Вход | " + ORGANIZATION_NAME
      }, [])

    const handleSubmit = async () => {
        loginUserHoro(enteredMail, entered);
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleSubmit();
        }
    };

    return (
        <div className="height-need">
            <MyToast error={generalError} closeAlert={closeAlert} />
            <div className="main2">
                <div className="two-sides-flex">
                    <div>
                        <div>
                            <div className="login-form-block">
                                <div className="enter-name-block">
                                    Введите Логин:
                                    <MyInputText
                                        name="login"
                                        onChange={(event) =>
                                            setEnteredMail(event.target.value)
                                        }
                                        onKeyDown={handleKeyDown}
                                        value={enteredMail}
                                    />
                                </div>
                                <div className="enter-name-block">
                                    Введите Пароль:
                                    <MyInputText
                                        onChange={(event) =>
                                            setEntered(event.target.value)
                                        }
                                        onKeyDown={handleKeyDown}
                                        value={entered}
                                        type="password"
                                    />
                                </div>
                                <div className="login-text">Используйте логин и пароль от компьютера</div>
                                {enteredError ? (
                                    <div className="errors-message-class">
                                        {enteredError}
                                    </div>
                                ) : (
                                    <div></div>
                                )}
                            </div>
                            <MyButton onClick={handleSubmit}>Войти</MyButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HoroLogin;
