import { BrowserRouter, Routes, Route } from "react-router-dom";
import NotFound from "./pages/NotFound";
import { AuthProvider } from "./context/AuthContext";
import PrivateRoute from "./utils/PrivateRoute";
import FormPage from "./pages/FormPage";
import CalendarPage from "./pages/CalendarPage";
import ProfilePage from "./pages/ProfilePage";
import CalendarContextWrapper from "./context/CalendarContextWrapper";
import Register from "./pages/Register";
import LoginGet from "./pages/LoginGet";
import LoginCheck from "./pages/LoginCheck";
import EmailConfirm from "./pages/EmailConfirm";
import EmailVerify from "./pages/EmailVerify";
import EventsPage from "./pages/EventsPage";

import HoroLogin from "./pages/HoroLogin";


import dayjs from 'dayjs';
import ConfirmPage from "./pages/ConfirmPage";
import PatchFormPage from "./pages/PatchFormPage";
import ConfirmEventPage from "./pages/ConfirmEventPage";
require('dayjs/locale/ru')
dayjs.locale('ru')

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <AuthProvider>
          <CalendarContextWrapper>
            <Routes>
              <Route path="*" element={<NotFound />} />
              <Route path="/" element={<PrivateRoute><CalendarPage /></PrivateRoute>} />
              <Route path="/create" element={<PrivateRoute><FormPage /></PrivateRoute>} />
              <Route path="/confirms" element={<PrivateRoute><ConfirmPage /></PrivateRoute>} />
              <Route path="/confirm/:id" element={<PrivateRoute><ConfirmEventPage /></PrivateRoute>} />
              <Route path="/events" element={<PrivateRoute><EventsPage /></PrivateRoute>} />
              <Route path="/event/:id" element={<PrivateRoute><PatchFormPage /></PrivateRoute>} />
              <Route path="/profile" element={<PrivateRoute><ProfilePage /></PrivateRoute>} />
              {/* <Route path="/login" element={<LoginGet />} /> */}
              <Route path="/login" element={<HoroLogin />} />
              {/* <Route path="/check" element={<LoginCheck />} />
              <Route path="/register" element={<Register />} />
              <Route path="/register/verify" element={<EmailVerify />} />
              <Route path="/register/confirm" element={<EmailConfirm />} /> */}
            </Routes>
          </CalendarContextWrapper>
        </AuthProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
